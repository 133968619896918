import { NextSeo } from 'next-seo';
import { useContext } from 'use-context-selector';
import { ContentSectionComponent } from '../contentSectionComponent/ContentSectionComponent';
import { NotFoundContext } from '../notFoundProviderContext/NotFoundProvider';

export const NotFoundSections = () => {
  const context = useContext(NotFoundContext);

  if (!context) return null;

  const { seo, sections } = context;

  return (
    <>
      <NextSeo title={seo.title || ''} description={seo.description ?? ''} />
      {sections.map((section, index) => {
        return <ContentSectionComponent key={index} section={section} />;
      })}
    </>
  );
};
