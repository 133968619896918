import { useQuery } from '@tanstack/react-query';
import { useRouter } from 'next/router';
import { type ReactNode } from 'react';
import { createContext } from 'use-context-selector';
import { storeLocale } from '@/root/constants';
import type { NotFoundQuery } from 'lib/contentful/__generated__/NotFound';
import { useNotFoundQuery } from 'lib/contentful/__generated__/NotFound';
import { contentfulQueryDataSource } from 'lib/contentful/dataSources';
import { formatContentfulSections } from 'utils/format/contentful/formatContentfulSections';
import type {
  FormattedContentfulSection,
  InputContentfulSections,
} from 'utils/types/contentfulSections';

export const NotFoundContext =
  createContext<ReturnType<typeof select>>(undefined);

export const NotFoundProvider = ({ children }: { children: ReactNode }) => {
  const { locale } = useRouter();

  const { data } = useQuery(
    useNotFoundQuery.getKey({ locale }),
    useNotFoundQuery.fetcher(contentfulQueryDataSource({}), {
      locale: storeLocale(locale),
    }),
    {
      select,
    }
  );

  return (
    <NotFoundContext.Provider value={data}>{children}</NotFoundContext.Provider>
  );
};

/**
 * Format the query data to be used in the context.
 */
const select = (query: NotFoundQuery) => {
  const { notFound } = query;

  if (!notFound) {
    return;
  }

  let sections: FormattedContentfulSection[] = [];

  if (notFound.sectionsCollection?.items) {
    sections = formatContentfulSections(
      notFound.sectionsCollection?.items as InputContentfulSections
    );
  }

  const seo = {
    title: notFound.seoTitle,
    description: notFound.seoDescription,
  };

  return {
    sections,
    seo,
  };
};
