import { QueryClient } from '@tanstack/react-query';
import type { GetStaticPropsContext } from 'next';
import { storeLocale } from '@/root/constants';
import { NotFoundProvider } from 'components/notFoundProviderContext/NotFoundProvider';
import { NotFoundSections } from 'components/notFoundSections/NotFoundSections';
import { useNotFoundQuery } from 'lib/contentful/__generated__/NotFound';
import { contentfulQueryDataSource } from 'lib/contentful/dataSources';
import { defaultStaticProps } from 'utils/defaultsStaticProps';

export default function NotFound() {
  return (
    <NotFoundProvider>
      <NotFoundSections />
    </NotFoundProvider>
  );
}

export const getStaticProps = async (context: GetStaticPropsContext) => {
  const locale = storeLocale(context.locale);

  const queryClient = new QueryClient();
  const staticProps = await defaultStaticProps(context, {
    queryClient,
    queries: [
      queryClient.prefetchQuery(
        useNotFoundQuery.getKey({ locale }),
        useNotFoundQuery.fetcher(contentfulQueryDataSource({}), {
          locale,
        })
      ),
    ],
  });

  return {
    props: {
      ...staticProps,
    },
  };
};
